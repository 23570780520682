<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports"/>
      </v-col>
    </v-row>


    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >

          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04) ">
                <p class="pa-4 font-extrabold text-xl text-blue" >Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                  outlined
                  placeholder="User Name"
                  v-model="searchParams.user_id"
                  item-value="id"
                  item-text="name"
                  :items="userNames"
                  :loading="isLoading"
                  @change="page=1"
                  class="q-text-field shadow-0"
                  dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      placeholder="From Date"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                      hide-details

                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.from_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      class="q-autocomplete shadow-0"
                      dense
                      v-model="date2Formatted"
                      outlined
                      placeholder="To Date"
                      background-color="#fff"
                      readonly
                      v-on="on"
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.to_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                >Go</v-btn
                >
              </v-flex>
            </v-col>

            <v-spacer></v-spacer>
            <!-- <v-col md="2" class="text-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="
                      checkExportPermission($modules.reports.system_log.slug)
                    "
                    color="#00b0af"
                    height="48"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >Export Report</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item @click="reportDownload('pdf')">
                    <v-list-item-icon>
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Export as PDF</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="reportDownload('excel')">
                    <v-list-item-icon>
                      <v-icon>mdi-file-excel-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Export as Excel</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col> -->
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">System Log Report</v-col>
                </v-row>
              </div>

            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <table class="table table-striped header-fixed">
                    <thead class="md-card-header sticky shadow-0">
                    <tr>
                      <th class="md-table-head sticky">
                        <div
                            class="
                                md-table-head-container md-ripple md-disabled
                                text-center
                              "
                        >
                          <div class="md-table-head-label">
                            Timestamp
                          </div>
                        </div>
                      </th>
                      <th class="md-table-head sticky">
                        <div
                            class="
                                md-table-head-container md-ripple md-disabled
                                text-center
                              "
                        >
                          <div class="md-table-head-label">
                            User Name
                          </div>
                        </div>
                      </th>
                      <th class="md-table-head sticky">
                        <div
                            class="
                                md-table-head-container md-ripple md-disabled
                              "
                        >
                          <div class="md-table-head-label text-center">
                            Description
                          </div>
                        </div>
                      </th>
                      <th class="md-table-head sticky">
                        <div
                            class="
                                md-table-head-container md-ripple md-disabled
                              "
                        >
                          <div class="md-table-head-label text-center">
                            Action
                          </div>
                        </div>
                      </th>
                    </tr>
                    </thead>

                    <tbody v-if="systemLogs.length > 0">
                    <template
                        v-for="(details, index) in systemLogs"
                    >
                      <tr :key="`obj_head_${index}`">
                        <!-- <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ searchParams.from_date | dateformat }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ searchParams.to_date | dateformat }}
                          </div>
                        </td> -->
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ details.created_at | timeStamp }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ details.first_name+' '+details.last_name }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ details.description }}
                          </div>
                        </td>
                        <td class="md-table-cell">
                          <div class="md-table-cell-container text-center">
                            {{ details.action }}
                          </div>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td :colspan="8">
                        <v-card-text class="pa-8 text-center">
                          <h3>No data found!</h3>
                        </v-card-text>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <v-pagination
                      class="mt-3 new-pagination"
                      v-model="page"
                      total-visible="7"
                      :length="totalPages"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: {BackButton},
  data() {
    return {
      totalPages: 0,
      page: 1,
      systemLogs: [],
      menu1: null,
      menu2: null,
      fileName: null,
      searchParams: {
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        user_id: null,
      },
      containerHeight: 400,
      facilities: [],
      userNames: [{ name: "All" }],
      isLoading: false,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getUserNames();
  },
  methods: {
    icon() {
      return "mdi-minus-box";
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName = this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      
      if (this.searchParams.user_id && this.searchParams.user_id != 'All') {
        url += `&user_id=${this.searchParams.user_id}`;
      }

      if (this.searchParams.user_name != undefined) {
        this.fileName += "-" + this.searchParams.user_name;
        url += `&user_name=${this.searchParams.user_name}`;
      }
      if (this.fileName != null)
        this.fileName = "System-Log-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "System-Log-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function(opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(
          `venues/reports/system-log-report${url}&page=${this.page}`
        )
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.totalPages = response.data.total_pages;
            this.systemLogs = data;
          } else {
              this.systemLogs = [];
            }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(report_type) {
      let link = "";
      if (report_type == "pdf") {
        link = "system-log-report-pdf";
      } else if (report_type == "excel") {
        link = "system-log-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, report_type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
    nameSearch(e) {
      this.searchParams.user_id = e.user_id;
      this.searchParams.user_name = e.name;
      this.page = 1;
      this.getReport();
    },
    getUserNames() {
      this.isLoading = true;
      this.$http
        .get(`venues/users/list-select-box`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            this.userNames = response.data.data;
            this.userNames.unshift({ name: "All" });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
</style>
